import { createSlice } from '@reduxjs/toolkit';

const brokerSlice = createSlice({
  name: 'broker',
  initialState: {
    brokers: [], // List of brokers { broker_id, broker_name }
    accounts: {}, // Mapping of broker_id to its account_infos
    strategies: {}, // Mapping of account_id to its strategies
    selectedBroker: "", // Currently selected broker_id
    selectedAccount: "", // Currently selected account_id
    selectedStrategy: "", // Currently selected strategy_id
    supportedAccounts: null,
    securityList: null
  },
  reducers: {
    setBrokers(state, action) {
      state.brokers = action.payload;
    },
    setAccounts(state, action) {
      const { brokerId, accounts } = action.payload;
      state.accounts[brokerId] = accounts;
    },
    setStrategies(state, action) {
      const { accountId, strategies } = action.payload;
      if(accountId)
        state.strategies[accountId] = strategies;
    },

    setSupportedAccounts(state, action) {
      state.supportedAccounts = action.payload
    },


    selectBroker(state, action) {
      if(action.payload)
        state.selectedBroker = action.payload; // Set selected broker ID
      else
        state.selectedBroker = null;
      state.selectedAccount = null; // Reset account on broker change
      state.selectedStrategy = null; // Reset strategy on broker change
    },

    selectAccount(state, action) {
      state.selectedAccount = action.payload; // Set selected account ID
      state.selectedStrategy = null; // Reset strategy on account change
    },
    selectStrategy(state, action) {
      state.selectedStrategy = action.payload; // Set selected strategy ID
    },


    saveSecurityList(state,action) {
      state.securityList = action.payload; //for all securities
    }

  },
});

export const { setBrokers, setAccounts, setStrategies, selectBroker, selectAccount, selectStrategy, selectBrokerName, setSupportedAccounts,saveSecurityList } = brokerSlice.actions;
export default brokerSlice.reducer;
