/*eslint-disable*/
import React, {useMemo, useRef} from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { useState, useEffect } from "react";
import { useGlobalState } from 'components/globalVariable';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from "reactstrap";
import classNames, { ShimmerTable } from 'react-shimmer-effects';
import { setPositionTableOptionsData, setFilterText } from '../container/PositionTableOptionSlice';
import TableRefresher from 'components/TableRefreshComponet/TableRefresher';
import PositionOrderForm from '../container/PositionOrderForm';
import OrderForm from 'components/OrderForm/Field/OrderForm';
import config  from "../../../config";

let strategyLists
const PositionsGrid = () => {
    
    const [count, setCount]             = useState(1);
    const [showSpinner, setShowSpinner] = useState(false);
    const [refreshTime, setRefreshTime] = useState(new Date().toLocaleTimeString([], { hour12: true }).replace('pm', 'PM'));
    const { broker, dates,security, portfolio, entity, strategy, zenSecIds } = useSelector(state => state.filter);
    const { columnOptions } = useSelector(state => state.positionTableOption);
    const {selectedStrategy, selectedBroker, selectedBrokerName, selectedAccount} =  useSelector(state => state.clientSelection)
    const dispatch = useDispatch()
    const [clientId] = useGlobalState("clientId")
    const [accountIds] = useGlobalState('accountIds')
    const [clientName] = useGlobalState('clientName');

    strategyLists = useSelector(state => state.filter.strategyList)
    const [rowData, setRowData] = useState();
    const filterText = useSelector(state => state.positionTableOption.filterText);
    const gridRef = useRef(null);

    const onGridReady = (params) => {
        gridRef.current = params.api; // Store the API reference
        calculateSum();
    };

    const getFilteredRows = () => {
        if (gridRef.current) {
            const filteredRows = [];
            gridRef.current.forEachNode((node) => {
                if (node.rowIndex !== null) { // Only include visible rows
                    filteredRows.push(node.data);
                }
            });
            return filteredRows;
        }
        return [];
    };
    const dateConversion = (val) => {
        const dateObj = new Date(val);
        const year = dateObj.getFullYear();
        const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); 
        const day = ('0' + dateObj.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    
    }

    const [isSingleDate,setIsSingleDate] = useState(true);
    useEffect(()=>{
       if(dateConversion(dates[0])===dateConversion(dates[1])){
        setIsSingleDate(true)
       }
       else{
        setIsSingleDate(false)
       }
    },[dates])

    const  cellClickedListener = ( ) => {
        setCount(count + 1);
    };

    const defaultColDef = {
        sortable: true,
        editable: false,
        filter: true,
        headerClass:{textAlign:"center"},
        cellStyle: params => {
            if(params?.column?.colId==='unrealizedPnl'){
                if (params.value < 0) {
                    return { color: "red", display: "flex",justifyContent: "flex-end"};
                }else {
                    return {display: "flex",justifyContent: "flex-end", color:"#00cc00"};
                }
            }
            else {
                return {display: "flex",justifyContent: "flex-end"};
            }
        }
    }

    const rowStyle = { background: "" };

    const getRowStyle = params => {
        if (params.value< 0) {
            return { color: "red" };
        }
    }

    const [isFetching,setIsFetching] = useState(true);
    
    useEffect(() => {
        setIsFetching(true);
    
        const body = {
            ...(dates[0].toLocaleDateString() !== new Date().toLocaleDateString() ? {
                startTimestamp: `${dateConversion(dates[0])} 00:00:00`,
                endTimestamp: `${dateConversion(dates[1])} 23:59:59`,
            } : {}),
            positionCompKeyFilter: {
                clientId: clientId,
                ...((zenSecIds === null|| zenSecIds?.length === 0)  ? {  } : { zenSecIds: zenSecIds}),
                
                ...((selectedAccount === "all" || selectedAccount === "" || selectedAccount === null || selectedAccount === undefined )  ? {  } : { accountIds : [selectedAccount]}),
                // Conditionally include strategyIds only if strategy is not empty
                ...((selectedStrategy === "all" || selectedStrategy === "" || selectedStrategy === null || selectedStrategy === undefined )  ? {  } : { strategyIds : [selectedStrategy]}),
                ...((selectedBroker === "all" || selectedBroker === "" || selectedBroker === null || selectedBroker === undefined) ? {}: { brokers: [selectedBrokerName] })  // Only include brokers if broker is not "ALL"
            }
        };
    
        const fetchBeta = async () => {
            try {
                const response = await fetch(config.REACT_APP_DEV_URL + 'trade/beta', {
                    method: "POST",
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                });
                const betaData = await response.json();
                return betaData;
            } catch (err) {
                console.log("Error in beta:", err);
                return [];
            }
        };
    
        const fetchPositions = async () => {
            try {
                const response = await fetch(config.REACT_APP_DEV_URL + 'trade/position', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                });
                const positionsData = await response.json();
                return positionsData;
            } catch (err) {
                console.log("Error in fetching positions:", err);
                return [];
            }
        };
    
        const mergeBetaWithPositions = (positions, betaData, dates) => {
            if (dateConversion(dates[0]) === dateConversion(dates[1])) {
                // If start and end dates are the same, map only using zenSecId
                const betaMap = new Map(
                    betaData.map(item => [`${item.positionCompositeKey.zenSecId}`, item.beta])
                );
        
                return positions.map(position => {
                    const key = `${position.positionCompositeKey.zenSecId}`;
        
                    return {
                        ...position,
                        beta: betaMap.get(key) || null
                    };
                });
            } else {
                // If start and end dates are different, map using both trade_date and zenSecId
                const betaMap = new Map(
                    betaData.map(item => [`${item.trade_date}-${item.positionCompositeKey.zenSecId}`, item.beta])
                );
        
                return positions.map(position => {
                    const key = `${position.date}-${position.positionCompositeKey.zenSecId}`;
                   
                    return {
                        ...position,
                        beta: betaMap.get(key) || null
                    };
                });
            }
        };
        
        
        
    
        const fetchData = async () => {
            try {
                const positionsData = await fetchPositions();
                const betaData = await fetchBeta();
    
                const mergedData = mergeBetaWithPositions(positionsData, betaData, dates);
                setRowData(mergedData);
                setRefreshTime(new Date().toLocaleTimeString([], { hour12: true }));
                setTimeout(() => setIsFetching(false), 750);
            } catch (err) {
                console.log("Error in fetchData:", err);
            }
        };
    
        fetchData();
        const intervalId = setInterval(fetchData, 60000);
    
        return () => {
            clearInterval(intervalId);
        };
    }, [zenSecIds, dates, count, clientName, strategy, broker, selectedAccount,selectedStrategy,selectedBroker, selectedBrokerName]);
     
    
    const containerStyle = useMemo(() => ({  height: 'auto', width : '100%' , overflow: 'hidden' }), []);
    const gridStyle = useMemo(() => ({ height: '580px', width : '1730px' }), []);
    const filterStyle = useMemo(() => ({ height: '50px', width : '100%' }), []);
    const tableStyle = useMemo(() => ({ height: '100%', width : '100%', "margin" : "15px", position: "relative", "border" : "2px solid black" }), []);
    const tdStyle = useMemo(() => ({ width : '50%' }), []);
    const halfStyle = useMemo(() => ({width : "500px", "padding-left" : "15px"}), []);

    const [open, setOpen] = React.useState(false);
    const [topRowData , setTopRowData] = useState({

        unrealizedPnl: 0,
        curMarketValue: 0,
    });

    function calculateSum()
    {
        let latestDate = dateConversion(dates[1]);
        let latestData;

        if(filterText !== "")
        {
            latestData = getFilteredRows()?.filter(row=>row?.date===latestDate);
        }
        else{
            latestData = rowData?.filter(row=>row?.date===latestDate);
        }
        if (rowData?.length) {
            let unrealizedPnlSum   = 0;
            let curMarketValueSum     = 0;
            latestData?.forEach(row => {
                unrealizedPnlSum += Math.round(row?.unrealizedPnl);
                curMarketValueSum += Math.round(row?.curMarketValue);
            });
            // rowData.forEach(row => {
            //     realizedLTDPnlsum += Math.round(row?.realizedLTDPnl);
            // });
            setTopRowData({
               
                unrealizedPnl: unrealizedPnlSum,
                curMarketValue: curMarketValueSum
            })
        }
        else{
            setTopRowData({
               
                unrealizedPnl: 0,
                curMarketValue: 0
            })
        }
    }
    useEffect(() => {
        calculateSum();
    }, [rowData, filterText]);

    const pinnedTopRowData = useMemo(() => {
        return [
           topRowData
        ];
    }, [topRowData]);
    const optionsUpdater = (stateList)=>{
        const newdata = columnOptions.map((option,i)=>{
            return {...option,sort:stateList[i]}
         })
        
        dispatch(setPositionTableOptionsData(newdata));
    }
    const handleSortingData = (e)=>{
        const state = e.columnApi?.getColumnState();
        const sortState = state?.map(item=>item.sort);
        optionsUpdater(sortState)
    }


    return (
        <>
        <TableRefresher cellClickedListener={cellClickedListener} showSpinner={isFetching}/>
            {
                <div style={gridStyle} className="ag-theme-alpine" >
                { isFetching?
                <ShimmerTable row={7} col={5} />
                :
                <>
                    <div className="absolute flex items-center space-x-4 my-2 top-[45px] z-10">
                        <div class=" inset-y-0 start-0 flex items-center ps-6 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input
                            type="text"
                            className='bg-slate-50 font-semibold rounded-sm px-4 ps-10 py-2 border text-gray-500'
                            placeholder=" Search by any field"
                            value={filterText}
                            onChange={(e)=>dispatch(setFilterText(e.target.value))}
                        />
                    </div>
                    <AgGridReact
                        //isSingleDate?[{...columnOptions[0],hide:true},...columnOptions.slice(1)]:
                        columnDefs = {[...columnOptions,
                            {
                                headerName: "", field: 'tradingSymbol',
                                headerClass: "ag-right-aligned-header",
                                width: 180,
                                sort: null,
                                cellRenderer:PositionOrderForm,
                                cellRendererParams: {
                                    tableData: rowData
                                }
                            }
                        ]}
                        defaultColDef = {defaultColDef}
                        filterStyle = {filterStyle}
                        onGridReady={onGridReady}
                        //pagination
                        //paginationPageSize={10}
                        pinnedTopRowData={pinnedTopRowData}
                        getRowStyle = {getRowStyle}
                        rowData = {rowData}
                        onSortChanged={handleSortingData}
                        //domLayout='autoHeight'
                        quickFilterText={filterText} 
                        >
                    </AgGridReact>
                </>
                }
                </div>
            } 
    </>
  

    )


}

export default PositionsGrid;
export {strategyLists}