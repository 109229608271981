/* eslint-disable */

import { useEffect, useRef, useState } from "react";
import DerivativeGraph from "./DerivativeGraph";
import getLowestDerivativeData, { getIndexEODPriceForCurrentDate } from "./CypherResult";
import {getHighestDerivativeData, getDerivativeFutures , getEquityEodPrice} from "./CypherResult";
import { setEquityClosePrice, incrementFetchCount, setFetchCount, setChartTitle, setDerivativeNodeZenID,  setIsDerivative,setIsIndex, setPopoverNode, setDerivativePopover, setShowChart, setNodePosition, setFirstLayoutStop, setHasFetched, setIsLoading } from "./DerivativeGraphSlice";
import { useDispatch, useSelector } from "react-redux";
import { setShowPopover , setPopoverPosition, setPopoverIndexLabels, setIsSector} from "../../CytoscapeGraph/container/MainTemplateSlice";
import { setShowForm } from "../../GraphTemplate/container/GraphTemplateSlice";
import { useLocation } from "react-router-dom";

const DerivativeGraphTempalte = ({elements}) => {
    const [derivativeData, setDerivativeData ] = useState([])
    const location = useLocation()
    const cyRef = useRef(null)
    const dispatch = useDispatch()
    const urlNodeName = location.pathname.split("/").pop().replace("%20",' ');
    const popoverNode = useSelector(state => state.derivativeGraphTemplate.derivativePopover).length !=0? useSelector(state => state.derivativeGraphTemplate.derivativePopover) : JSON.parse(sessionStorage.getItem(urlNodeName));
    const popoverNodeData = useSelector(state => state.derivativeGraphTemplate.derivativePopover).length !=0 ? popoverNode?.data() : popoverNode?.data;
    const popoverIndexLabels = useSelector(state => state.mainTemplate.popoverIndexLabels)
    const equityClosePrice = useSelector(state => state.derivativeGraphTemplate.equityClosePrice)
    const showPopover = useSelector(state => state.mainTemplate.showPopover)
    const siblingEquity = JSON.parse(sessionStorage.getItem(`${popoverNodeData?.parent} Sector`));
    const parentData = JSON.parse(sessionStorage.getItem(popoverNodeData?.parent));
    const indexData = JSON.parse(sessionStorage.getItem(`${popoverNodeData?.properties?.name} Index`));
    const indexEdges = JSON.parse(sessionStorage.getItem(`${popoverNodeData?.properties?.name} edge`));    
    const fetchCount = useSelector(state => state.derivativeGraphTemplate.fetchCount)
    // const derivativeIsIndex = useSelector(state => state.derivativeGraphTemplate.isIndex)
    const isIndex = useSelector(state => state.derivativeGraphTemplate.derivativeIsIndex)
    const isSector = useSelector(state => state.mainTemplate.isSector)
    const backgroundColor = useSelector(state => state.graphTemplate.backgroundColor)
    const firstLayoutStop = useSelector(state => state.derivativeGraphTemplate.firstLayoutStop)
    const nodePosition = useSelector(state => state.derivativeGraphTemplate.nodePosition)
    const filteredElements = [];
    const hasFetched = useSelector(state => state.derivativeGraphTemplate.hasFetched);

    let equityName = popoverNodeData?.properties.name
    if(equityName === 'NIFTY 50') equityName = 'NIFTY'
    if(equityName === 'NIFTY BANK') equityName = 'BANKNIFTY'
    
    useEffect(() => {
        let nodeName = location.pathname.split("/").pop().replace("%20",' ')
        if(nodeName === 'NIFTY 50') nodeName = 'NIFTY'
        if(nodeName === 'NIFTY BANK') nodeName = 'BANKNIFTY'
        if( nodeName !== equityName)
        {
            equityName = popoverNodeData?.properties?.name;
            if(equityName === 'NIFTY 50') equityName = 'NIFTY'
            if(equityName === 'NIFTY BANK') equityName = 'BANKNIFTY'
            const node = cyRef?.current?.nodes()?.filter(data => data.data().properties.name === nodeName)[0]
            dispatch(setDerivativePopover(node))
            dispatch(setIsIndex(node.data().label === 'Index' ? true : false)) 
            dispatch(setIsSector(node.data().label === 'Sector' ? true : false))
            dispatch(setIsDerivative((node.data().label === `Future`) ||(node.data().label === `Options`) ))
            dispatch(setDerivativeNodeZenID(node.data().zen_id))
            dispatch(setChartTitle(node.data().properties.name))

            if(!((node.data().label === `Future`) ||(node.data().label === `Options`) ) && !isIndex && !isSector)
            {
                const labels = node.parent().neighbourhood().nodes().map(element => element.data().properties.name)
                dispatch(setPopoverIndexLabels(node.data().label === 'Index' ? '' : [...labels]))
            }
            if((node.data().label === `Future`) ||(node.data().label === `Options`) )
            {  
                const labels = cy.nodes().filter(data => data.data().label === 'Index')
                dispatch(setPopoverIndexLabels(labels.map(data => data.data().properties.name)))
            }
            // dispatch(setHasFetched(false));
            
    
        }
        if(!isIndex && !hasFetched)
            {
                setDerivativeData([]);
                getEquityEodPrice(nodeName, setEquityClosePrice, dispatch)
                dispatch(setFetchCount(0))
            }
            else if(!hasFetched) 
            {
                setDerivativeData([]);
                getIndexEODPriceForCurrentDate(nodeName, setEquityClosePrice, dispatch)
                dispatch(setFetchCount(0))
            }
        // else{
        //     console.log("Else Derivative data : ",derivativeData, derivativeData.length);
        //     if(!isIndex && !hasFetched)
        //     {
        //         getEquityEodPrice(nodeName, setEquityClosePrice, dispatch)
        //         dispatch(setFetchCount(0))
        //     }
        //     else
        //     {
        //        if(!hasFetched) 
        //        {
        //             getIndexEODPriceForCurrentDate(nodeName, setEquityClosePrice, dispatch)
        //             dispatch(setFetchCount(0))
        //        }
        //     }
        //     dispatch(setHasFetched(true));
        // }  
        
        
    },[location])
    
    document.addEventListener('keydown', function(event){
        if(event.key === 'Escape')
        {
          dispatch(setShowPopover(false))
          dispatch(setShowForm(false))
        }
    })

    // useEffect(()=> {
    //     console.log("Present in popover node");
    //     if(!hasFetched){
    //         if(!isIndex)
    //         {
    //             setDerivativeData([])
    //             getEquityEodPrice(equityName, setEquityClosePrice, dispatch)
    //             dispatch(setFetchCount(0))
    //         }
    //         else
    //         {
    //             setDerivativeData([])
    //             getIndexEODPriceForCurrentDate(popoverNodeData?.properties?.name, setEquityClosePrice, dispatch)
    //             dispatch(setFetchCount(0))
    //         }
    //         dispatch(setHasFetched(true));
    //     }
        
        
    // }, [popoverNodeData, hasFetched]);

    useEffect(() => {
        if(equityClosePrice!='' && fetchCount === 0)
        {
            getLowestDerivativeData(equityName,equityClosePrice, setDerivativeData,fetchCount, incrementFetchCount, dispatch)
            getHighestDerivativeData(equityName,equityClosePrice, setDerivativeData, fetchCount, incrementFetchCount, dispatch)
            getDerivativeFutures(equityName, equityClosePrice, setDerivativeData, fetchCount, incrementFetchCount, dispatch)
        }
    },[equityClosePrice]);
    
    if(Object.keys(popoverNodeData)?.length !== 0)
    {
        // siblingEquity.sort((a,b) => a.data.properties.name.localeCompare(b.data.properties.name))
        if(!isIndex)
        {
            // if(popoverIndexLabels.length === 2)
            // {
            //     console.log(elements.filter(item => item.data.label === 'Index').map(item => item.data.properties))
            // }
            // console.log(popoverNode.parent().connectedEdges().map((node) => node.source().data()));
            // console.log(popoverNode.parent().connectedEdges().map((node) => node.data()))
            filteredElements.push({data : parentData});
            indexData?.map(data => {
                filteredElements.push({data : data});
            });
            indexEdges?.map(data => filteredElements.push({data : data}))

            // popoverNode.parent().connectedEdges().map((node) => filteredElements.push({data : node.source().data()})) //Adding index data
            // popoverNode.parent().connectedEdges().map((node) => filteredElements.push({data : node.data()})) //Adding index edge data
            
            // filteredElements.push({data: popoverNode.parent().data()})  // Adding sector data 
    
            for(const i in siblingEquity)
                filteredElements.push(siblingEquity[i]) 
    
        }
        else{
            filteredElements.push({data : popoverNodeData})
        }
        
        filteredElements.push({data : {id : `${equityName} Future`,label : 'FutureHolder', 
        properties : {name :`${equityName} Future` }}})
        filteredElements.push({data : {id : `${equityName} Options`, label : 'OptionHolder',
        properties : {name :`${equityName} Options` }}})
         
        for(const i in derivativeData)
            filteredElements.push
            (
                {
                    data : {
                        zen_id : derivativeData[i]?.zen_id,
                        label : derivativeData[i].instrument_type === 'FUT'? `Future` : `Options`,
                        // properties : {name : derivativeData[i].trading_symbol},
                        instrument_type : derivativeData[i].instrument_type,
                        properties : { name : derivativeData[i].name + "\n" + 
                        (derivativeData[i].instrument_type === 'FUT'? '':(derivativeData[i].strike) + "\n") + 
                        derivativeData[i].instrument_type + "\n" + 
                        derivativeData[i].trading_symbol.substring(derivativeData[i].name.length, derivativeData[i].name.length+ 5) 
                        },
                        parent : derivativeData[i].instrument_type === 'FUT'? `${equityName} Future` : `${equityName} Options`
                    }
                }
            )
        filteredElements.push({data : {source : popoverNodeData?.id, label : 'Future Holder', target : `${equityName} Future`}})
        filteredElements.push({data : {source : popoverNodeData?.id, label : 'Option Holder', target : `${equityName} Options`}})
    
    }

    const options = {
        userZoomingEnabled: true, 
        userPanningEnabled: true, 
        boxSelectionEnabled: false,
        autoungrabify : false,
        autounselectify : false
    }

    const handleRightClick = (node, cy) => {
        dispatch(setPopoverNode(node))            
        dispatch(setPopoverPosition({ x : node.renderedPosition().x > 1770 ? 1770 : node.renderedPosition().x , y :node.renderedPosition().y > 800 ? 800 : node.renderedPosition().y}))
        dispatch(setShowPopover(true))
        dispatch(setIsIndex(node.data().label === 'Index' ? true : false)) 
        dispatch(setIsSector(node.data().label === 'Sector' ? true : false))
        dispatch(setIsDerivative((node.data().label === `Future`) ||(node.data().label === `Options`) ))
        dispatch(setDerivativeNodeZenID(node.data().zen_id))
        dispatch(setChartTitle(node.data().properties.name))
        
        if(!((node.data().label === `Future`) ||(node.data().label === `Options`) ) && !isIndex && !isSector)
        {
            const labels = node.parent().neighbourhood().nodes().map(element => element.data().properties.name)
            dispatch(setPopoverIndexLabels(node.data().label === 'Index' ? '' : [...labels]))
        }
        if((node.data().label === `Future`) ||(node.data().label === `Options`) )
        {  
            const labels = cy.nodes().filter(data => data.data().label === 'Index')
            dispatch(setPopoverIndexLabels(labels.map(data => data.data().properties.name)))
        }
    }
    const hideForm = () => {
        dispatch(setShowForm(false))
    }

    const hideChart = () => {
    dispatch(setShowChart(false))
    }

    const handleZoom = (cy) => {
        const zoom = cy.zoom()
        if(zoom >= 3)
            cy.zoom(3)
        if(zoom <= 0.2)
            cy.zoom(0.2)
    }

    const handleLayoutStop = (cy) => {
        if(firstLayoutStop)
        {
          const nodePosition = {}
          cy.nodes().forEach(node => {
            nodePosition[node.id()] = {x : node.position().x , y : node.position().y}
          })
          dispatch(setNodePosition(nodePosition))
          dispatch(setFirstLayoutStop(false))
        }
        else{
          cy.nodes().forEach(node => {
            const nodeId = node.id();
            const newPosition = nodePosition[nodeId];
            if (newPosition) {
                node.position(newPosition);
            }
        });
        
          
        }
        cy.fit();            
        cy.center()
    }
    return (
    <DerivativeGraph
        filteredElements = {filteredElements}
        cyRef = {cyRef}
        showPopover = {showPopover}
        dispatch = {dispatch}
        fetchCount = {fetchCount}
        options = {options}
        handleRightClick = {handleRightClick}
        hideForm = {hideForm}
        hideChart = {hideChart}
        handleZoom = {handleZoom}
        backgroundColor = {backgroundColor}
        handleLayoutStop = {handleLayoutStop}
        firstLayoutStop = { firstLayoutStop}
    />
    )
}

export default DerivativeGraphTempalte;