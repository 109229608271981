/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react';

const initialState = {
  equityClosePrice : '',
  fetchCount : 0,
  isDerivative : false,
  derivativeNodeZenID : "",
  showChart : false,
  chartTitle : '',
  chartData : [],
  chartIndexData : [],
  isIndex : false,
  popoverNode : [],
  derivativePopover : [],
  derivativeIsIndex : false,
  defaultZoom : 0,
  nodePosition: '',
  firstLayoutStop : true,
  siblingEquity : null,
  hasFetched : false,
  isLoading : true
};

const DerivativeGraphSlice = createSlice({
  name: 'derivativeGraphTemplate',
  initialState,
  reducers: {
    setEquityClosePrice(state, action){
        state.equityClosePrice = action.payload
    },
    
    setIsDerivative(state, action){
      state.isDerivative = action.payload
    },

    setFetchCount(state, action){
        state.fetchCount = action.payload
    },

    incrementFetchCount(state){
        state.fetchCount += 1 
    },

    setDerivativeNodeZenID(state, action){
      state.derivativeNodeZenID = action.payload
    },

    setShowChart(state, action){
      state.showChart = action.payload
    },

    setChartTitle(state, action){
      state.chartTitle = action.payload
    },

    setChartData(state, action){
      state.chartData = action.payload
    },

    setChartIndexData(state, action){
      state.chartIndexData = action.payload
    },

    setIsIndex(state, action){
      state.isIndex = action.payload
    },


    setPopoverNode(state, action){
      state.popoverNode = action.payload
    },

    setDerivativePopover(state, action){
      state.derivativePopover = action.payload
    },

    setDerivativeIsIndex(state, action){
      state.derivativeIsIndex = action.payload
    },

    setDefaultZoom(state, action){
      state.defaultZoom = action.payload
    },

    setNodePosition(state, action){
      state.nodePosition = action.payload
    },

    setFirstLayoutStop(state, action){
      state.firstLayoutStop = action.payload
    },

    updateNodePosition(state, action){
      const position = action.payload[1]
      state.nodePosition = {...state.nodePosition, [action.payload[0]] : {x: position.x, y : position.y}} 
    },
    
    setSiblingEquity(state, action){
      state.siblingEquity = action.payload
    },
    setHasFetched(state, action){
      state.hasFetched = action.payload
    },
    setIsLoading(state, action){
      state.isLoading = action.payload
    }
  }
});

export const { setEquityClosePrice, incrementFetchCount, setFetchCount , setIsDerivative,
   setDerivativeNodeZenID, setShowChart, setChartTitle, setChartData, setChartIndexData,
    setIsIndex, setPopoverNode, setDerivativePopover, setDerivativeIsIndex, setDefaultZoom,
    setNodePosition, setFirstLayoutStop, updateNodePosition, setSiblingEquity, setHasFetched, setIsLoading } = DerivativeGraphSlice.actions;
export default DerivativeGraphSlice.reducer;
