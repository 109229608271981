/*eslint-disable*/
import config from '../../../config';
const getLowestDerivativeData = (equity, equityClosePrice, setDerivativeData,fetchCount, incrementFetchCount, dispatch) => {
  fetch(`${config.CORS_URL}getLatestLowerStrikeDerivative?equity=${encodeURIComponent(equity)}&strike=${encodeURIComponent(Math.floor(equityClosePrice))}`, {
      timeoutDuration: 3000000000000000
    }, {
            // mode : 'no-cors',
            method : 'GET'
          })
          .then(response => {
            if (!response.ok) {
                throw new Error("It is error");
              }
              return response.json();
          })
          .then(data => { 
            setDerivativeData(prevData => [...prevData, ...data])
            dispatch(incrementFetchCount( ))
          })
          .catch(error => console.error("Error",error))
  }

  export const getHighestDerivativeData = (equity,equityClosePrice, setDerivativeData,fetchCount, incrementFetchCount, dispatch) => {
    
    fetch(`${config.CORS_URL}getLatestHigherStrikeDerivative?equity=${encodeURIComponent(equity)}&strike=${encodeURIComponent(Math.floor(equityClosePrice))}`,{
      timeoutDuration: 3000000000000000
    }, {
            // mode : 'no-cors',
            method : 'GET'
          })
          .then(response => {
            if (!response.ok) {
                throw new Error("It is error");
              }
              return response.json();
          })
          .then(data => { 
            setDerivativeData(prevData => [...prevData, ...data])
            dispatch(incrementFetchCount())

          })
          .catch(error => console.error("Error",error))
  }

  export const getDerivativeFutures = (equity, equityClosePrice, setDerivativeData, fetchCount, incrementFetchCount, dispatch) => {
   
    fetch(`${config.CORS_URL}getDerivativeFutures?equity=${encodeURIComponent(equity)}`, {
      timeoutDuration: 3000000000000000
    }, {
            // mode : 'no-cors',
            method : 'GET'
          })
          .then(response => {
            if (!response.ok) {
                throw new Error("It is error");
              }
              return response.json();
          })
          .then(data => {
            setDerivativeData(prevData => [...prevData, ...data])
            dispatch(incrementFetchCount())

          })
          .catch(error => console.error("Error",error))
  }

  export const getEquityEodPrice = (equity, setEquityClosePrice, dispatch) => {
   
    // const pastMonthDate = new Date()
    // pastMonthDate.setDate(pastMonthDate.getDate() - 1)
    // const formattedPastMonth = parseInt(pastMonthDate.getMonth()) >= 10 ? parseInt(pastMonthDate.getMonth()+1) : "0"+parseInt(pastMonthDate.getMonth()+ 1) 
    // const formattedPastDate =  pastMonthDate.getDate() > 10 ? pastMonthDate.getDate() : "0"+pastMonthDate.getDate()
    // const pastDate = formattedPastDate+"-"+formattedPastMonth+"-"+pastMonthDate.getFullYear()
    
    fetch(`${config.CORS_URL}getEquityEODPriceForCurrentDate?equity=${encodeURIComponent(equity)}`, {
      timeoutDuration: 3000000000000000
    }, {
            // mode : 'no-cors',
            method : 'GET'
          })
          .then(response => {
            if (!response.ok) {
                throw new Error("It is error");
              }
              return response.json();
          })
          .then(data => { 
            dispatch(setEquityClosePrice(data[0].close))
            
          })
          .catch(error => console.error("Error",error))
  }

  export const getIndexEODPriceForCurrentDate = (index_name, setEquityClosePrice, dispatch) => {
    // const pastMonthDate = new Date()
    // pastMonthDate.setDate(pastMonthDate.getDate() - 1)
    // const formattedPastMonth = parseInt(pastMonthDate.getMonth()) >= 10 ? parseInt(pastMonthDate.getMonth()+1) : "0"+parseInt(pastMonthDate.getMonth()+ 1) 
    // const formattedPastDate =  pastMonthDate.getDate() > 10 ? pastMonthDate.getDate() : "0"+pastMonthDate.getDate()
    // const pastDate = formattedPastDate+"-"+formattedPastMonth+"-"+pastMonthDate.getFullYear()
    
    fetch(`${config.CORS_URL}getIndexEODPriceForCurrentDate?index_name=${encodeURIComponent(index_name)}`, {
      timeoutDuration: 3000000000000000
    }, {
            // mode : 'no-cors',
            method : 'GET'
          })
          .then(response => {
            if (!response.ok) {
                throw new Error("It is error");
              }
              return response.json();
          })
          .then(data => { 
            dispatch(setEquityClosePrice(data[0].close))
            
          })
          .catch(error => console.error("Error",error))
  }
  export default getLowestDerivativeData;