
import { createSlice } from '@reduxjs/toolkit';

const presentDate= new Date()

const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        dates: [presentDate, presentDate],
        security:"",
        exchange:"ZEN_EXCHANGE",
        strategy:null,
        portfolio:"",
        entity:"",
        broker:"ALL",
        userList : [],
        orderStrategyId : 1,
        exchangeFilter: "ALL",
        strategyList: [],
        broker_infos : [],
        account : null, //account in the top navbar
        zenSecIds : []
    },
    reducers: {
        saveDates: {
            reducer: (state, action) => {
                state.dates = action.payload.dates;
            },
            prepare: (dates) => ({ payload: { dates } }),
        },
        saveSecurity: {
            reducer: (state, action) => {
                state.security = action.payload.security;
            },
            prepare: (security) => ({ payload: { security } })
        },
        saveExchange: {
            reducer: (state, action) => {
                state.exchange = action.payload.exchange;
            },
            prepare: (exchange) => ({ payload: { exchange} })
        },
        saveStrategy: {
            reducer: (state, action) => {
                state.strategy= action.payload.strategy;
            },
            prepare: (strategy) => ({ payload: { strategy } })
        },
        savePortfolio:{
            reducer: (state, action) => {
                state.portfolio = action.payload.portfolio;
            },
            prepare: (portfolio) => ({ payload: { portfolio } })
        },
        saveEntity:{
            reducer: (state, action) => {
                state.entity = action.payload.entity
            },
            prepare: (entity) => ({ payload: {entity} })
        },
        saveBroker:{
            reducer: (state, action) => {
                state.broker = action.payload.broker;
                state.strategy = "ALL";
                state.account = null;
            },
            prepare: (broker) => ({ payload: { broker} })
        },
        setUserList(state, action){
            state.userList = action.payload
        },
        setOrderStrategyId(state, action){
            state.orderStrategyId = action.payload
        },
        setExchangeFilter(state, action){
            state.exchangeFilter = action.payload
        },
        saveBrokerInfo(state,action){
            state.broker_infos = action.payload
        },
        saveStrategyList(state,action){
            state.strategyList = action.payload
        },
        saveAccount(state, action){
            state.account = action.payload;
            state.strategy = "ALL";
        },
        setZenSecIds(state, action){
            state.zenSecIds = action.payload
        }

    },
});

export const { saveDates,saveSecurity,saveExchange,saveStrategy,saveBroker,saveEntity,savePortfolio, 
    setUserList, setOrderStrategyId, setExchangeFilter,saveBrokerInfo,saveStrategyList, saveAccount, setZenSecIds } = filterSlice.actions;

export default filterSlice.reducer;
