/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedClients : null
};

const DemoNavbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setSelectedClients(state, action){
        state.selectedClients = action.payload
    }
  }
});

export const { setFormOpen, setOrderType, setAutocompleteValue, setSecurities, setLotSize, setLots, setType,
                setIsSecuritySelect, setQtyFocus, setLotsFocus, setOpen, setPendingData, setExchange,
                setOrderValue, setUpdateData, setIsUpdateForm, setIsDeleteBtn, setSelectedClients} = DemoNavbarSlice.actions;
export default DemoNavbarSlice.reducer;
