import { createSlice } from '@reduxjs/toolkit';

// const startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
const startDate = new Date(2024,5,1)
const endDate= new Date();


const PnlLineChartSlice = createSlice({
    name: 'pnlLineChart',
    initialState: {
        dateRange: [startDate  , endDate],
        targetSymbol : null,
        lineChartData : null,
        stocks : [],
        isFetching : false,
        type : "YTD",
        pnlType : "unrealized",
        isBtnClick : false,
        zenSecId : []
    },
    reducers: {
        setDateRange(state, action){
            state.dateRange = action.payload
        },
        setTargetSymbol(state, action){
            state.targetSymbol = action.payload
        },
        setLineChartData(state, action){
            state.lineChartData = action.payload
        },
        setStocks(state, action){
            state.stocks = action.payload;
        },
        setIsFetching(state, action){
            state.isFetching = action.payload
        },
        setType(state, action){
            state.type = action.payload
        },
        setPnlType(state, action){
            state.pnlType = action.payload
        },
        setIsBtnClick(state, action){
            state.isBtnClick = action.payload
        },
        setZenSecId(state, action){
            state.zenSecId = action.payload;
        }
    },
});

export const { setDateRange, setTargetSymbol, setLineChartData, setStocks, setIsFetching, setType, setPnlType, setIsBtnClick,
                setZenSecId } = PnlLineChartSlice.actions;

export default PnlLineChartSlice.reducer;
